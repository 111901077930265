import * as React from "react";
import Header from "../../Components/Header/Header";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";
import CaseHistory from "../../Components/CaseHistory/CaseHistory";
import ContactFooter from "../../Components/ContactFooter/ContactFooter";
import Partnership from "../../Components/Partnership/Partnership";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";

const CaseStudiesPage = () => {
  return (
    <>
      <Seo locale="it" page="case-studies" />
      <Header isDarkTheme={true} lang="it" />
      <HamburgerMenu />
      <CaseHistory lang="it" />
      <ContactFooter isDarkTheme={true} lang="it" />
      <Partnership lang="it" />
      <Footer lang="it" />
    </>
  );
};

export default CaseStudiesPage;
